<template>
    <div>
        <surround-loader :is-loading="isLoading">

            <h2 class="mb-0 mx-4 mt-4 mb-2">User setup</h2>
            <div class="p-2">
                <div class="px-4">
                    <div class="bg-light p-3 mb-4">
                        <div class="mb-2 d-flex align-items-center">
                            <b-badge variant="primary">
                                Admin access
                            </b-badge>
                            <strong class="ml-2">Allows a user to manage users, edit system and view all organisation
                                data</strong>
                        </div>
                        <div class="d-flex align-items-center">
                            <b-badge variant="success">
                                Billing access
                            </b-badge>
                            <strong class="ml-2">Allows a user to view invoices, update billing details</strong>
                        </div>
                    </div>
                    <div v-if="this.$store.state.user.organisation.workspace_id == null">

                        <h5>Supervisors</h5>
                        <p>Can initiate and assign Right to Work checks to users, as well as view the output of the
                            Right to
                            Work applications.</p>
                        <setup-user supervisor v-on:new-user="loadUsers"></setup-user>

                        <div class="mt-3">
                            <surround-loader :is-loading="isLoadingUsers">
                                <div v-if="loadingError != null">
                                    <div class="alert alert-danger d-flex justify-content-between align-items-center">
                                        <span>There was an error loading supervisors.</span>
                                        <b-button size="sm" variant="primary" @click="loadUsers">Retry</b-button>
                                    </div>
                                </div>
                                <div v-else-if="!isLoadingUsers && supervisors.length > 0">
                                    <div :supervisors="supervisors" v-for="item in supervisors">
                                        <setup-user-cell v-on:delete="loadUsers" :user="item"></setup-user-cell>
                                    </div>
                                </div>
                                <div v-else-if="!isLoadingUsers">
                                    <div class="alert alert-info">
                                        Supervisors will appear here once created
                                    </div>
                                </div>
                            </surround-loader>
                        </div>


                        <div class="mt-3 pt-3 border-top">
                            <h5>Users</h5>
                            <p>Can initiate and carry out Right to Work checks from the mobile app and web portal.</p>

                            <setup-user :supervisors="supervisors" v-on:new-user="loadUsers"></setup-user>

                            <div class="mt-3">
                                <surround-loader :is-loading="isLoadingUsers">
                                    <div v-if="loadingError != null">
                                        <div class="alert alert-danger d-flex justify-content-between align-items-center">
                                            <span>There was an error loading users.</span>
                                            <b-button size="sm" variant="primary" @click="loadUsers">Retry</b-button>
                                        </div>
                                    </div>
                                    <div v-else-if="!isLoadingUsers && users.length > 0">
                                        <div v-for="item in users">
                                            <setup-user-cell v-on:delete="loadUsers" :user="item"></setup-user-cell>
                                        </div>

                                    </div>
                                    <div v-else-if="!isLoadingUsers">
                                        <div class="alert alert-info">
                                            Users will appear here once created
                                        </div>
                                    </div>
                                </surround-loader>
                            </div>

                        </div>
                    </div>
                    <div v-else>
                        <p><strong>Supervisors</strong> can initiate and assign Right to Work checks to users, as well as view the output of the
                            Right to
                            Work applications</p>
                        <p><strong>Users</strong> can initiate and carry out Right to Work checks from the mobile app and web portal</p>
                        <p >Please use the boxes below to add users from your workspace to the Access Right to Work system</p>
                        <workspace-users :update-ref="updateRef"></workspace-users>
                    </div>

                    <div class="font-weight-bold mt-4">
                        All new Supervisors and Users will receive an email with instructions on downloading the mobile
                        app.
                    </div>

                </div>
            </div>


            <div class="p-4  d-flex justify-content-between">
                <b-button :to="{name:'setup_system_setup'}" variant="outline-secondary">
                    Back
                </b-button>

                <b-button @click="saveForm" variant="success">
                    Proceed to Privacy Agreement and Contract
                </b-button>
            </div>
        </surround-loader>
        <b-modal :no-close-on-backdrop="true" :hide-header=true :hide-footer=true v-model="showModal">
            <div class="text-right position-absolute" style="right: 10px; top:10px;">
                <a class="btn btn-sm text-primary" @click="() => showModal = false"><b-icon-x-circle></b-icon-x-circle></a>
            </div>
            <router-view></router-view>
        </b-modal>
    </div>
</template>

<script>
    import Vue from "vue";
    import SurroundLoader from "../SurroundLoader";
    import {getResource, saveResource} from "../../modules/api/methods";
    import {
        organisationCurrent, organisationSetupSystemSetup, organisationSetupUsers, organisationSetupUserSetup,
    } from "../../modules/api/endpoints";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import SetupUser from "./SetupUser";
    import _ from 'lodash'
    import SetupUserCell from "./SetupUserCell";
    import {containsErrors} from "../../modules/helpers/helpers";
    import WorkspaceUsers from "../users/WorkspaceUsers";

    export default {
        name: 'setup_system_setup',
        data: function () {
            return {
                updateRef : 1,
                showModal: false,
                isLoading: false,
                isLoadingUsers: false,
                loadingError: null,
                formData: {},
                errors: {},
                users: [],
                supervisors: [],
                newSupervisor: {},
                newUser: {},
                docCheckOptions: [
                    {value: 'on_upload', text: 'Automatically on upload'},
                    {value: 'on_approve', text: 'Automatically on approval'},
                    {value: 'on_request', text: 'All users can request'},
                    {value: 'on_request_supervisor', text: 'Supervisors can request'},
                    {value: 'off', text: 'Off'},
                ],
            }
        },
        watch: {
            $route: {
                immediate: true,
                handler: function (newVal, oldVal) {
                    console.log("IN HERE MAN",newVal);
                    this.showModal = newVal.meta && newVal.meta.showModal;
                    // if(newVal.params.data_updated !== undefined) {
                    //     this.request = _.clone(this.request)
                    // }
                    // this.super = (this.$router.currentRoute.name.indexOf('Admin') !== -1 && this.$store.getters.isSuper);
                }
            },
            showModal: function (newVal, oldVal) {
                if (!newVal) {
                    if(this.$router.currentRoute.name !== 'setup_users_setup') {
                        this.$router.replace({'name': 'setup_users_setup', params: {'data_updated': true}}).catch((err) => {});
                    }
                    this.updateRef = Math.random()
                }
                if(newVal !== oldVal && !newVal) {
                    this.updateRef = Math.random()
                }
            },
        },
        created() {
            this.loadModel();
            if(!this.$store.getters.isWorkspaceAccount) {
                this.loadUsers();
            }
        },
        methods: {
            loadModel() {
                this.isLoading = true;
                getResource(organisationCurrent).then((resp) => {
                    var data = resp.data.success.data;
                    if (data.domains !== undefined) {
                        var domains = [];
                        _.each(data.domains, function (obj) {
                            if (obj.domain !== undefined) domains.push(obj.domain);
                        });
                        data.domains = domains;
                    }

                    if (data.ips !== undefined) {
                        var ips = [];
                        _.each(data.ips, function (obj) {
                            if (obj.ip_address !== undefined) ips.push(obj.ip_address);
                        });
                        data.ips = ips;
                    }

                    this.formData = data;
                    this.isLoading = false;
                }).catch((ex) => {
                    this.isLoading = false;
                });
            },
            loadUsers() {
                this.isLoadingUsers = true;
                this.loadingError = null;
                var vm = this;
                getResource(organisationSetupUsers).then((resp) => {
                    console.log("ALL USERS", resp);
                    vm.users = resp.data.success.filter(function (obj) {
                        return obj.user_type === 'user'
                    });
                    vm.supervisors = resp.data.success.filter(function (obj) {
                        return obj.user_type === 'supervisor'
                    });

                }).catch((ex) => {
                    this.loadingError = ex;
                }).finally(() => {
                    vm.isLoadingUsers = false;
                });
            },
            saveForm() {
                var vm = this;
                vm.errors = {};
                vm.isLoading = true;
                getResource(organisationSetupUserSetup).then(function (resp) {
                    //UPDATE THE ORG TO ALLOW TO PROCEED TO NEXT STEP
                    console.log(resp.data.success.data);
                    vm.$store.dispatch('updateOrganisation', resp.data.success.data).then(() => {
                        vm.$router.push({name: 'setup_contract'}).catch(error => { console.log("ROUTE ERROR",error) });
                    });
                }).catch(function (err) {
                    console.log(err);
                    if (containsErrors(err)) {
                        vm.errors = err.data.errors;
                    }
                }).finally(function () {
                    vm.isLoading = false;
                })
            },
            ipValidator(tag) {
                return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(tag)
            },
        },
        components: {WorkspaceUsers, SetupUserCell, SetupUser, ErrorDisplay, SurroundLoader},
    }
</script>

